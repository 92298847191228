import { InputMask } from "@react-input/mask";
import { Suspense, useCallback, useEffect, useRef } from "react";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useApi from "../hooks/useApi";
import Swal from "sweetalert2";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export function Component() {
  const formRef = useRef<HTMLFormElement>(null);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const navigate = useNavigate();

  const api = useApi();

  const { formState, register, handleSubmit, setValue, setError } = useForm({
    defaultValues: {
      cpf: "",
      recaptcha_token: "",
    },
  });

  const loadRecaptcha = useCallback(async () => {
    if (executeRecaptcha) {
      executeRecaptcha("recover_password").then((token) => setValue("recaptcha_token", token));
    }
  }, [executeRecaptcha, setValue]);

  const handleSuccess = useCallback(
    (response: any) => {
      Swal.fire({
        title: "Sucesso!",
        html: response.message,
        icon: "success",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      }).then(() => navigate("/login"));
    },
    [navigate]
  );

  const handleError = useCallback(
    (error: any) => {
      Object.entries<any>(error.data ?? []).forEach(([field, messages]) => {
        if (field === "recaptcha_token") {
          loadRecaptcha();
        }

        setError(field as any, { type: "manual", message: messages[0] });
      });

      Swal.fire({
        icon: "error",
        title: "Oops...",
        html: error.message,
      });
    },
    [loadRecaptcha, setError]
  );

  const onSubmit = useCallback(
    async (data: any) => {
      if (executeRecaptcha) {
        executeRecaptcha("recover_password").then((token) => setValue("recaptcha_token", token));
      }

      Swal.fire({
        title: "Processando...",
        text: "Aguarde um momento, estamos processando sua solicitação.",
        icon: "info",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });

      api.recovery(data).then(handleSuccess).catch(handleError);
    },
    [executeRecaptcha, setValue, api, handleSuccess, handleError]
  );

  useEffect(() => {
    register("recaptcha_token", {
      required: "O Google reCAPTCHA não foi carregado corretamente. Atualize a página e tente novamente.",
    });

    loadRecaptcha();
  }, [register, loadRecaptcha]);

  return (
    <Suspense fallback={<h1>Carregando...</h1>}>
      <section>
        <Container>
          <h1 className="text-center mb-5">RECUPERAR SENHA</h1>

          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <Form
                ref={formRef}
                onSubmit={handleSubmit(onSubmit)}
                className="mb-5"
                noValidate
              >
                <div className="mb-4">
                  <h2 className="fs-5 mb-3">DADOS DE ACESSO</h2>

                  <Row>
                    <Col xs={12} className="mb-3">
                      <Form.Label htmlFor="cpf">CPF*</Form.Label>
                      <Form.Control
                        id="cpf"
                        as={InputMask}
                        mask="___.___.___-__"
                        placeholder="___.___.___-__"
                        replacement={{ _: /\d/ }}
                        isInvalid={!!formState.errors.cpf}
                        {...register("cpf", {
                          required: "Este campo é obrigatório",
                        })}
                        autoFocus
                      />
                      <Form.Control.Feedback type="invalid">
                        {formState.errors.cpf?.message}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>

                  {formState.errors.recaptcha_token && (
                    <Alert variant="danger" className="mb-3">{formState.errors.recaptcha_token.message}</Alert>
                  )}
                </div>

                <div className="d-flex gap-3 justify-content-center">
                  <Button type="submit" variant="primary" className="button">
                    ENVIAR
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </Suspense>
  );
}
